/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBTGIDoUqMJX5qi6mmgPgAB6xnOTtuRw2k",
  "appId": "1:371297811996:web:2a7f8e5e54b151de7869d9",
  "authDomain": "schooldog-i-valdosta-ga.firebaseapp.com",
  "measurementId": "G-B50V52K69V",
  "messagingSenderId": "371297811996",
  "project": "schooldog-i-valdosta-ga",
  "projectId": "schooldog-i-valdosta-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-valdosta-ga.appspot.com"
}
